import { Route, Routes, Navigate, Outlet, useNavigate } from 'react-router-dom'
import { PrivateRoute } from '@emerald-works/react-auth'
import { useConnectionContext, useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { Dashboard } from './pages/Dashboard';
import { SignIn } from './pages/Signin';
import { OauthInit } from './pages/OauthInit';
import { ConnectWithZoom } from './pages/ConnectWIthZoom/ConnectWithZoom';
import { useEffect, useMemo } from 'react';
import { useUserHook } from './slices/user/useUserHook';
import TopBar from './components/TopBar/TopBar';
import SignUp from './pages/SignUp';
import Confirmation from './pages/Confirmation';
import { Settings } from './pages/Settings';

const PreLoginRoute: React.FC = () => {
  const { connection } = useConnectionContext()
  const authenticated = useMemo(() => connection?.session?.isAuthenticated, [connection?.session?.isAuthenticated])
  return authenticated ? <Navigate to='/dashboard' replace /> : <Outlet />;
}

const PostLoginRoute = () => {
  const { connection, isConnected } = useConnectionContext()
  const userHook = useUserHook()
  const navigate = useNavigate()
  const [getData] = useCorvusEventList([{
    eventName: "Zoom User Data Was Requested", onSuccess: (res: any) => {
      if (!res) navigate('/connect')
      userHook.setUserData(res)
    }
  }])

  useEffect(() => {
    if (connection?.session?.isAuthenticated && isConnected) {
      getData.trigger()
    }
  }, [
    connection?.session?.isAuthenticated,
    isConnected,
    getData
  ])

  return <>
    <TopBar />
    <Outlet />
  </>
}

const ApplicationRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<PreLoginRoute />}>
        <Route path="/" element={<SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/confirmation" element={<Confirmation />} />
      </Route>

      <PrivateRoute path="/connect" element={<ConnectWithZoom />} />
      <PrivateRoute path='/' element={<PostLoginRoute />}>
        <PrivateRoute path="/dashboard" element={<Dashboard />} />
        <PrivateRoute path="/settings" element={<Settings />} />
        <PrivateRoute path="/zoom-oauth" element={<OauthInit />} />
      </PrivateRoute>
    </Routes>
  )
}

export default ApplicationRoutes
