import { AxiosInstance, AxiosRequestConfig } from "axios";
import { useCallback } from "react";


const useApi = (api: AxiosInstance) => {
  const get = useCallback(async (path: string, config: AxiosRequestConfig = {}) => {
    const response = await api.get(path, config)
    return response.data
  }, [api])

  const post = useCallback(async (path: string, data: any, config: AxiosRequestConfig = {}) => {
    const response = await api.post(path, data, config)
    return response.data
  }, [api])

  const patch = useCallback(async (path: string, data: any, config: AxiosRequestConfig = {}) => {
    const response = await api.patch(path, data, config)
    return response.data
  }, [api])

  const put = useCallback(async (path: string, data: any, config: AxiosRequestConfig = {}) => {
    const response = await api.put(path, data, config)
    return response.data
  }, [api])

  const remove = useCallback(async (path: string, config: AxiosRequestConfig = {}) => {
    const response = await api.delete(path, config)
    return response.data
  }, [api])

  return { get, post, patch, put, remove, api }
}

export default useApi
