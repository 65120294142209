import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { useEffect } from "react"
import { syncRecordingsList } from "src/slices/recording/recordingSlice"
import { RecordingList } from "src/components/RecordingList/RecordingList"
import { Box } from '@mui/material'

const Dashboard = () => {
  const [synchronizeRecordings] = useCorvusEventList([syncRecordingsList])
  useEffect(() => {
    synchronizeRecordings.trigger()
  }, [synchronizeRecordings])

  return (
    <Box display='flex' flexWrap='wrap' gap={4} marginTop={4}>
      <RecordingList loading={synchronizeRecordings.isWorking} />
    </Box>
  )
}

export { Dashboard }
