import axios from 'axios'

const restApi = axios.create({ baseURL: process.env.REACT_APP_API_GATEWAY_ADDRESS })

const setAuthorizationHeader = (token: string) => {
  localStorage.setItem('token', token);
}

export default restApi
export { setAuthorizationHeader }
