import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { Delete } from '@mui/icons-material';
import { IMeeting } from 'src/interfaces/IMeeting';
import { useRecordingHook } from 'src/slices/recording/useRecordingHook';

interface DeleteOnZoomButtonProps {
  meeting: IMeeting
}
const DeleteOnZoomButton: React.FC<DeleteOnZoomButtonProps> = ({ meeting }) => {
  const { confirmDeletion } = useRecordingHook()
  const [deleteMeetingRecordsFromZoom] = useCorvusEventList([{ eventName: 'Delete Meeting Records From Zoom', onSuccess: () => {
    confirmDeletion(meeting)
  } }])
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    deleteMeetingRecordsFromZoom.trigger({ meetingUuid: meeting.uuid })

    setOpen(false)
  }
  if (meeting.deleted_on_zoom) return null;
  return (
    <>
      <Tooltip title='Delete Meeting files on Zoom'>
        <IconButton aria-label="video" color='primary' onClick={() => setOpen(true)}>
          <Delete color='error' />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Zoom Recording
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete <b>{meeting.topic}</b> recordings on Zoom?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant='contained'>No</Button>
          <Button onClick={handleClick} color='error' variant='outlined'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { DeleteOnZoomButton }
