import restApi from "../services/restApi";
import useApi from "./useApi";


const useRestApi = () => {
  const { api, ...methods } = useApi(restApi)
  return { ...methods }
}

export default useRestApi
