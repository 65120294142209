import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { Box, Typography } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import styles from './ConnectWithZoom.module.css'

enum ConnectStatus {
  Loading,
  Connected,
  NotConnected
}

const appName = process.env.REACT_APP_APP_NAME || 'VideoSquad'

const ConnectWithZoom = () => {
  const [status, setStatus] = useState(ConnectStatus.Loading)
  const navigate = useNavigate()
  const [verifyIntegration] = useCorvusEventList([{
    eventName: "Verify Zoom Integration", onSuccess: ({ result }: { result: boolean }) => {
      result ? setStatus(ConnectStatus.Connected) : setStatus(ConnectStatus.NotConnected)
    }
  }])
  const [generateAuthUrl] = useCorvusEventList([{
    eventName: "Zoom Generate Auth Url",
    onSuccess: (result: { url: string }) => window.open(result.url, '_self')
  }])

  const connect = async () => {
    generateAuthUrl.trigger()
  }

  useEffect(() => {
    verifyIntegration.trigger()
  }, [verifyIntegration])

  useEffect(() => {
    if (status === ConnectStatus.Connected) {
      navigate('/dashboard')
    }
  }, [status, navigate])

  if (status === ConnectStatus.Loading) return <>Loading...</>
  return (<Box className={styles.wrapper}>
    <Typography variant='h4'>Welcome to {appName}</Typography>
    <Typography>Looks like its your first time here</Typography>
    <Typography>Click the button below to connect with Zoom</Typography>
    <LoadingButton loading={generateAuthUrl.hasBeenTriggered}
      variant='outlined'
      onClick={connect}>Connect</LoadingButton>
  </Box>)
}

export { ConnectWithZoom }
