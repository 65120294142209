import { CircularProgress } from "@mui/material"
import { useRecordingHook } from "src/slices/recording/useRecordingHook"
import RecordingCard from "../RecordingCard/RecordingCard"
import styles from './RecordingList.module.css'

interface RecordingListProps {
  loading: boolean
}

export const RecordingList: React.FC<RecordingListProps> = ({ loading }) => {
  const { recordings } = useRecordingHook()

  if (loading) return <div className={styles.mainwrapper}><CircularProgress /></div>
  return (<div className={styles.mainwrapper}>{recordings.map(r => <RecordingCard key={r.uuid.toString()} {...r} />)}</div>)
}
