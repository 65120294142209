import { useEffect } from "react"
import { useConnectionContext, useEvent } from '@emerald-works/react-event-bus-client'
import { useSession } from '@emerald-works/react-auth'
import { useLocation, useNavigate } from "react-router-dom"
import useRestApi from "src/hooks/useRestApi"
import { useUserHook } from "src/slices/user/useUserHook"

const OauthInit = () => {
  const { isConnected } = useConnectionContext()
  const navigate = useNavigate()
  const { session } = useSession()
  const { search } = useLocation()
  const { get } = useRestApi()
  const userHook = useUserHook()
  const [instantiateConnection] = useEvent([{
    eventName: 'Zoom Instantiate Connection', onSuccess: (res: any) => {
      userHook.setUserData(res.user)
      navigate('/dashboard')
    }
  }])

  useEffect(() => {
    if (!isConnected) return
    const url = new URLSearchParams(search)
    const code = url.get('code')
    instantiateConnection.trigger({ code })
  }, [get, search, session?.isAuthenticated, isConnected, instantiateConnection])
  return <>Connecting... please wait</>
}

export { OauthInit }
