import { useState } from 'react'
import TextField from '@mui/material/TextField'
import { Auth } from 'aws-amplify'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, } from '@mui/material'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'
import useInput from 'src/hooks/useInput'

const SignIn = () => {
  const [loading, setLoading] = useState(false)
  const { state } = useLocation()
  const navigate = useNavigate()
  const { value: email, bind: bindEmail } = useInput('', { email: true })
  const { value: password, bind: bindPassword } = useInput('')
  const context = useConnectionContext()

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    await Auth.signIn(email, password)
    context.reloadConnection()
    navigate('/connect')
    setLoading(false)
  }

  return (
    <div>
      <form
        style={{ display: 'flex', flexDirection: 'column', width: '50%', minWidth: '500px', margin: 'auto', gap: '1rem' }}
        onSubmit={handleSubmit}
      >
        <h1 style={{ fontSize: '22px', fontWeight: 600 }}>Sign in with your email</h1>
        <TextField label='Email' {...bindEmail} type='email' />
        <TextField label='Password' type='password' {...bindPassword} />
        <Button
          variant='contained'
          size='large'
          type='submit'
          disabled={loading}
        >
          {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
          Login to Your Account
        </Button>
        <Button onClick={() => { navigate('/signup', { state }) }}>Create a new account</Button>
      </form>
    </div>
  )
}

export { SignIn }
