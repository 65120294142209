import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { IMeeting } from "src/interfaces/IMeeting";
import { updateRecording } from "./recordingSlice";



export const useRecordingHook = () => {
  const { recordings } = useAppSelector((state) => state.recording);
  const dispatch = useAppDispatch()
  const confirmDeletion = (meeting: IMeeting) => {
    dispatch(updateRecording({ ...meeting, deleted_on_zoom: true }))
  }

  return {
    recordings,
    confirmDeletion
  };
}
