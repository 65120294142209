import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMeeting } from 'src/interfaces/IMeeting';

export interface RecordingState {
  recordings: IMeeting[];
}

const initialState: RecordingState = {
  recordings: []
};

export const recordingSlice = createSlice({
  name: 'recording',
  initialState,
  reducers: {
    setRecordings: (state, action: PayloadAction<IMeeting[]>) => {
      state.recordings = action.payload
    },
    appendRecordings: (state, action: PayloadAction<IMeeting[]>) => {
      state.recordings = [...state.recordings, ...action.payload]
    },
    updateRecording: (state, action: PayloadAction<IMeeting>) => {
      const { payload } = action;
      state.recordings = state.recordings.map(i => {
        if (i.uuid !== payload.uuid) return i;
        return payload;
      })
    }
  }
});

export const { appendRecordings, setRecordings, updateRecording } = recordingSlice.actions;

export default recordingSlice.reducer;

export const syncRecordingsList = {
  eventName: 'Synchronize Account Recordings',
  onSuccess: [{ redux: true, action: setRecordings }]
}
