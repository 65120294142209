import { useMemo, useState } from 'react';
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material"
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import { IFile, IMeeting } from 'src/interfaces/IMeeting';
import { downloadUrl } from 'src/helpers';

interface RecordingDownloadDropdownProps {
  meeting: IMeeting
  type: IFile['file_type']
}
const RecordingDownloadDropdown:React.FC<RecordingDownloadDropdownProps> = ({ meeting, type }) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'MP4': return <VideoFileIcon />
      case 'M4A': return <AudioFileIcon />
      default: return null
    }
  }, [type])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fileUrls, setFileUrls] = useState<{ [key: string]: string }>({});
  const [fileUrlLoading, setFileUrlLoading] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [getPreSignedUrl] = useCorvusEventList([{
    eventName: "Fetch Recording Link",
    onSuccess: ({ url, file }: { url: string, file: IFile }) => {
      setFileUrls(s => ({ ...s, [file.id]: url }))
      setFileUrlLoading(false)
      downloadUrl(url)
    }
  }])


  const handleDownload = (file: IFile) => {
    if (fileUrls[file.id]) {
      downloadUrl(fileUrls[file.id])
      return
    }
    setFileUrlLoading(true)
    getPreSignedUrl.trigger({ meetingUuid: meeting.uuid, file: file })
    handleClose()
  }

  return (
    <>
      <IconButton aria-label="video" color='primary' onClick={handleClick}>
        {fileUrlLoading ? <CircularProgress size={24} /> : icon}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {meeting.recording_files.filter(f => f.file_type === type).map(f => (
          <MenuItem key={f.id} onClick={() => handleDownload(f)}>{f.id}</MenuItem>
        ))}
      </Menu>
    </>
  )
}

export { RecordingDownloadDropdown }
