import { useState } from 'react'
import TextField from '@mui/material/TextField'
import { Auth } from 'aws-amplify'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Alert } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import useInput from 'src/hooks/useInput'

interface IInfo { error: boolean, message: string, severity?: string }

const SignUp = () => {
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState<IInfo>({ error: false, message: '', severity: '' })
  const navigate = useNavigate()
  const { state } = useLocation()
  const { value: email, bind: bindEmail } = useInput('', { email: true })
  const { value: password, bind: bindPassword } = useInput('')
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput('')

  const handleSignUp = async (e: any) => {
    e.preventDefault()
    setLoading(true)

    if (password !== confirmPassword) {
      setInfo({ error: true, message: 'Passwords do not match', severity: 'error' })
      setLoading(false)
      return
    }
    try {
      await Auth.signUp({
        username: email,
        password: confirmPassword,
        attributes: {
          email
        }
      })
      setInfo({ error: false, message: 'Signup was successful' })
      navigate('/confirmation', { state: { ...(state as Object), email, sent: true } })
    } catch (error: any) {
      setInfo({ error: true, message: error.message })
    }
    setLoading(false)
  }

  return (
    <div>
      <form
        style={{ display: 'flex', flexDirection: 'column', width: '50%', minWidth: '500px', margin: 'auto', gap: '1rem' }}
        onSubmit={handleSignUp}>
        <h1 style={{ fontSize: '22px', fontWeight: 800 }}>
          New Account Registration
        </h1>
        <TextField label='Email' {...bindEmail} type='email' />
        <TextField label='Password' type='password' {...bindPassword} />
        <TextField
          label='Confirm Password'
          type='password'
          {...bindConfirmPassword}
        />
        {info.message.length > 0 && <Alert severity={info.error ? 'error' : 'success'}>{info.message}</Alert>}
        <Button
          variant='contained'
          size='large'
          type='submit'
          disabled={loading}
        >
          {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
          Sign Up
        </Button>

        <Button onClick={() => { navigate('/signin', { state }) }}>Sign in</Button>
      </form>
    </div>
  )
}
export default SignUp
