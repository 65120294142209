import { useState, useEffect, useCallback } from 'react'
import TextField from '@mui/material/TextField'
import { Auth } from 'aws-amplify'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert } from '@mui/material'
import useInput from 'src/hooks/useInput'

interface ILocationState {
  email?: string
  sent?: boolean
  redirectTo?: string
}

const Confirmation = () => {
  const { state } = useLocation()
  const { email, sent, redirectTo } = state as ILocationState
  const [loading, setLoading] = useState(false)
  const [localSent, setLocalSent] = useState(sent)
  const [info, setInfo] = useState({ error: false, message: '' })
  const navigate = useNavigate()
  const { value: code, bind: bindCode } = useInput('', { code: true })

  const resendCode = useCallback(async () => {
    if (!email) return
    try {
      await Auth.resendSignUp(email)
    } catch (error: any) {
      setInfo({ error: true, message: error.message })
      setTimeout(() => navigate('/signin'), 3000)
    }
  }, [navigate, email])

  useEffect(() => {
    if (!localSent && email) {
      resendCode()
      setLocalSent(true)
    }
  }, [resendCode, localSent, email])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    if (!email) return
    try {
      await Auth.confirmSignUp(email, code)
      alert('The account was confirmed successfully.')
      if (redirectTo) {
        navigate(redirectTo)
      } else {
        navigate('/dashboard')
      }
    } catch (error: any) {
      setInfo({ error: true, message: error.message })
    }
    setLoading(false)
  }

  return (

    <div>
      <form
        style={{ display: 'flex', flexDirection: 'column', width: '50%', minWidth: '500px', margin: 'auto', gap: '1rem' }}
        onSubmit={handleSubmit}
      >
        <h1 style={{ fontSize: '22px', fontWeight: 800 }}>
          Verify Your Account
        </h1>
        <TextField label='Verification Code' {...bindCode} />
        {info.message.length > 0 && <Alert severity={info.error ? 'error' : 'success'}>{info.message}</Alert>}
        <Button
          variant='contained'
          size='large'
          type='submit'
          disabled={loading}
        >
          {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
          Verify your account
        </Button>
      </form>
    </div>

  )
}
export default Confirmation
