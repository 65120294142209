import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { IMeeting } from 'src/interfaces/IMeeting';
import placeholder from 'src/assets/placeholder.png';
import { Box } from '@mui/material';
import styles from './RecordingCard.module.css'
import { RecordingDownloadDropdown } from '../RecordingDownloadDropdown/RecordingDownloadDropdown';
import { DeleteOnZoomButton } from '../DeleteOnZoomButton/DeleteOnZoomButton';

export default function RecordingCard(meeting: IMeeting) {
  const videoFile = useMemo(() => meeting.recording_files.find(f => f.file_type === 'MP4'), [meeting])
  return (
    <Box className={styles.mainwrapper}>
      <img className={styles.image} src={placeholder} title="placeholder" alt="video thumbnail" />
      <Box p={2} display='flex' flexDirection='column' flex={1} width='-webkit-fill-available'>
        <Box className={styles.cardcontent}>
          <Typography gutterBottom variant="h5" component="div" sx={{ height: '64px' }}>
            {meeting.topic}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {meeting.start_time}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {(+(videoFile?.file_size || 0) / 1000000).toFixed(2).toString()} Mb
          </Typography>
        </Box>
        {meeting.internal_status !== 'completed' ? null :
          <Box className={styles.cardactions}>
            <RecordingDownloadDropdown meeting={meeting} type='MP4' />
            <RecordingDownloadDropdown meeting={meeting} type='M4A' />
            <DeleteOnZoomButton meeting={meeting} />
          </Box>}
      </Box>
    </Box>
  );
}
