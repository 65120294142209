import { Box, CircularProgress, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { updateUserAccountSettingsEv } from 'src/slices/user/userSlice'
import { useUserHook } from 'src/slices/user/useUserHook'
import { LoadingButton } from '@mui/lab'

const Settings = () => {
  const [updateUserAccountSettings] = useCorvusEventList([updateUserAccountSettingsEv])
  const { user_data } = useUserHook()

  const onSubmit = (e: any) => {
    e.preventDefault()
    const { auto_delete_zoom_recordings_on_sync } = e.target
    const newSettings = {
      auto_delete_zoom_recordings_on_sync: auto_delete_zoom_recordings_on_sync.checked
    }
    updateUserAccountSettings.trigger({ newSettings })
  }

  if (!user_data) return (
    <Box display='flex' margin={4} flexDirection='column'>
      <CircularProgress />
    </Box>
  )

  return (
    <Box display='flex' marginTop={4} flexDirection='column' alignItems='center' gap={2}>
      <Typography variant='h5'>Account Settings</Typography>
      <form onSubmit={onSubmit}>
        <Box display='flex' flexDirection='column' gap={2}>
          <FormGroup>
            <FormControlLabel
              name='auto_delete_zoom_recordings_on_sync'
              control={<Switch defaultChecked={user_data.user_account_settings?.auto_delete_zoom_recordings_on_sync} />}
              label="Auto delete recordings on Zoom on Sync"
            />
          </FormGroup>
          <LoadingButton variant='outlined' fullWidth={false} type='submit' loading={updateUserAccountSettings.isWorking}>
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  )
}

export { Settings }
