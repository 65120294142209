import { useCallback } from "react";
import { useConnectionContext } from '@emerald-works/react-event-bus-client'
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { setUserData, UserData } from "./userSlice";
import { Auth } from 'aws-amplify'
import { deleteAllCookies } from "src/helpers";
import { useNavigate } from "react-router-dom";

export const useUserHook = () => {
  const dispatch = useAppDispatch();
  const context = useConnectionContext()
  const navigate = useNavigate()

  const { access_token, refresh_token, user_data } = useAppSelector((state) => state.user);

  const setUserDataDispatch = useCallback((user_data: UserData) => {
    dispatch(setUserData(user_data));
  }, [dispatch]);

  const logout = useCallback(() => {
    Auth.signOut();
    dispatch(setUserData(undefined));
    localStorage.clear();
    deleteAllCookies();
    context.reloadConnection()
    navigate('/signin')
  }, [dispatch, context, navigate])

  return {
    access_token,
    refresh_token,
    user_data,
    setUserData: setUserDataDispatch,
    logout
  };
}
