import Routes from './Routes';
import { EventBusProvider } from '@emerald-works/react-event-bus-client'
import { AuthProvider } from '@emerald-works/react-auth'
import { useNavigate } from 'react-router-dom';
import './App.css';
import { useMemo } from 'react';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';

const eventBusAuthTokenConfig = {
  generate: true,
  apiGatewayAddress: process.env.REACT_APP_API_GATEWAY_ADDRESS,
  awsRegion: process.env.REACT_APP_API_GATEWAY_REGION,
  generateTokenEndpoint: '/generate-token'
}

const amplifyAuthConfig = (params: { tenantKey: string }) => {
  const baseUrl = window.location.host
  const cookieUrl = window.location.hostname
  return {
    region: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: cookieUrl,
      path: '/',
      expires: 365,
      secure: true
    },
    oauth: {
      domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
      scope: ['email', 'phone', 'profile', 'openid'],
      redirectSignIn: `https://${baseUrl}/${params.tenantKey}/sso/signin`,
      redirectSignOut: `https://${baseUrl}/${params.tenantKey}/signout`,
      responseType: 'code'
    }
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2c3e50',
    },
    secondary: {
      main: '#34495e',
    },
  },
});

function App() {
  const config = useMemo(() => amplifyAuthConfig({ tenantKey: 'default' }), [])
  const navigate = useNavigate()
  const onAuthNeededRedirection = () => {
    navigate('/signin')
  }
  return (
    <AuthProvider
      onAuthNeededRedirection={onAuthNeededRedirection}
      amplifyAuthConfig={config}
      allowGuestUsers
      signOutPath='/signout'
      eventBusAuthTokenConfig={eventBusAuthTokenConfig}
      LoadingAuthComponent={<div>Loading auth</div>}
      AuthenticatingAuthComponent={<div>Authenticating...</div>}
    >
      <EventBusProvider
        eventBusURL={process.env.REACT_APP_EVENT_BUS_URL}
        connectionParams={{
          tenantKey: 'default'
        }}
        useAuthProvider
        namespace='app-name'
        waitForConnection
        LoadingComponent={<div>Loading...</div>}
      >
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </EventBusProvider>
    </AuthProvider>
  );
}

export default App;
